export const FaqsItems = [
{
///////////////////////////////////
entryTitle: 'GENERAL QUESTIONS',
///////////////////////////////////
items: [
{
    question: 'What is the Immediate Live Trading Account? ',
  answer: `
  The Immediate Live Trading Account allows traders to start with a fully funded account without needing to complete an assessment phase.
`},


{
  question: 'How do I withdraw the gains in my Immediate Live Trading Account? ',
  answer: `
Traders can request a withdrawal of the profits in their funded account through their trader dashboard at any time. However, withdrawals are limited to one request
every 30 days. <b>The minimum withdrawal amount is the greater of $100 or 1% of the account's starting balance.</b>
<br/>
When a withdrawal is approved, we will also withdraw our share of the gains, and your max trailing drawdown will lock in at your starting balance. The trailing drawdown does not reset when you request a
withdrawal. Example: You have taken an account from $100,000 to $120,000. You then request a withdrawal of $16,000. In this scenario, you will receive $12,800
and we would retain $3,200. This would also take the balance of the account down to $104,000, and your Maximum Trailing Drawdown is locked in at $100,000.
So, you would have $4,000 maximum you could lose on the account before it would violate the Maximum Trailing Drawdown rule. If you take a full withdrawal of the
gains in your Funded Account, the Maximum Trailing Drawdown will lock in at the starting balance and trigger the Maximum Trailing Drawdown breach rule,
resulting in the forfeiture of your Funded Account.
`},

{
  question: 'How will I see the charge on my Statement? ',
  answer: `
  Charges come across in the name of dashboardanalytix.com.
`},

{
  question: 'What is the minimum age I must be to be part of your program?',
  answer: `
You must be at least 18 years of age, or the applicable minimum legal age in your country, to purchase an Immediate Live Trading Account account.
`},

{
  question: 'Do your accounts charge commissions? ',
  answer: `
Immediate Live Trading Accounts receive the same pricing and commissions as charged by our Liquidity Provider to other, self-funded, retail trading accounts.
`},

{
  question: 'How are affiliates credited?',
  answer: `
Affiliates are credited for referrals when a user creates an account using a link or discount code provided by the Affiliate.
`},

{
  question: 'What are the trading hours? ',
  answer: `
Trading hours are generally set by the Liquidity Provider, unless set by our rules. We do not have any control over the trading hours. You can see the trading hours
for each product by using the following methods:
<br/>
DXtrade – Right click symbol, select “Instrument Info”
<br/>
MatchTrader – Click symbol to expand, select “Info”
<br/>
cTrader – Navigate to Symbol Window, scroll down to see “Market Hours” for selected symbol
`},

{
  question: 'What Countries are accepted? ',
  answer: `
Immediate Live Trading Accounts are prohibited for US domiciled customers. Subject to compliance with applicable laws and regulations, traders from all other countries,
excluding OFAC listed countries, can take part in our program, unless otherwise limited at the Company’s discretion.
`},

{
  question: 'What is the leverage? ',
  answer: `
We offer up to 50:1 leverage on Forex and Metals, up to 10:1 leverage on Indices, up to 5:1 leverage on Oils and up to 2:1 leverage on Cryptocurrencies.
`},

{
  question: 'How many Immediate Live Trading Accounts may I have active at one time ',
  answer: `
Maximum Funded Limits:<br/>
● A maximum of $100,000 in active Immediate Live Trading Accounts per person is permitted.
This can be composed of mulitple accounts but the combined sum of their starting balance value can't be more than $100,000
`},

{
  question: 'What Platform can I trade on? ',
  answer: `
Our technology is currently integrated with DXtrade, MatchTrader and cTrader platforms.
`},

{
  question: 'What products can I trade? ',
  answer: `
You can trade any products streamed by the Liquidity Provider into the available platforms, as such products may change from time to time. This includes FX pairs
and CFD Indices, Commodities, Metals, and Cryptocurrencies.
`},

{
  question: 'Where do I track the progress of my account? ',
  answer: `
Upon purchasing an Immediate Live Trading Account, you will receive access to a trader dashboard where you can monitor your Immediate Live Trading Account. The dashboard is
updated in near real time as we calculate your account metrics. It is your responsibility to monitor your breach levels.
`},

{
  question: 'What Countries are accepted? ',
  answer: `
Immediate Live Trading Accounts are prohibited for US domiciled customers. Subject to compliance with applicable laws and regulations, traders from all other countries,
excluding OFAC listed countries, can take part in our program, unless otherwise limited at the Company’s discretion.
`},

{
  question: 'What brokers/liquidity providers do you use? ',
  answer: `
We utilize an aggregate source of pricing and liquidity from multiple brokers and liquidity providers. The current liquidity providers use a number of different tier 1
banks, prime brokers, market makers and other liquidity sources to derive their pricing, and those underlying liquidity providers change constantly. Aggregating
liquidity providers while maintaining multiple sources of liquidity allows us to provide competitive pricing and execution, while reducing reliance on a sole liquidity
provider.
<br/>
All market pricing and trade executions are provided by the brokers, without any alterations or modifications on our part. Moreover, we do not adjust transaction
costs such as bid-offer spreads, markups/markdowns or swaps beyond what is set by our brokers.
Please note that the composition of liquidity providers may vary depending on the trading platform.
`},

]},

{
  ///////////////////////////////////
  entryTitle: 'Trading Rules',
  ///////////////////////////////////
  items: [
    {
      question: 'Can I trade during News Events? ',
      answer: `
Opening a position within 3 minutes before or after a News Event is prohibited. Any traders identified as having opened a position during a News Event are subject
to having that position closed and the associated P&L removed from their account, having the leverage on their account reduced or having their account breached
altogether. The Company has sole and absolute discretion in determining what constitutes a News Event. This rule is intended to protect the integrity of our
program and is not meant to penalize traders who inadvertently trade through a news event.
`},

{
  question: 'What is the policy on Prohibited Trading Activity? ',
  answer: `
You are also prohibited from using any trading strategy that is expressly prohibited by the Company or the Liquidity Providers it uses. Such prohibited trading
(“Prohibited Trading”) shall include, but not be limited to:
<br/> ● Exploiting errors or latency in the pricing and/or platform(s) provided by the Liquidity Provider
<br/> ● Utilizing non-public and/or insider information
<br/> ● Front-running of trades placed elsewhere
<br/> ● Trading in any way that jeopardizes the relationship that the Company has with a Liquidity Provider or may result in the canceling of trades
<br/> ● Trading in any way that creates regulatory issues for the Liquidity Provider
<br/> ● Utilizing any third-party strategy, off-the-shelf strategy or one marketed to pass challenge accounts
<br/> ● Utilizing one strategy to pass an assessment and then utilizing a different strategy in a funded account, as determined by the Company in cooperation with Prop
Account, LLC at their discretion
<br/> ● Attempting to arbitrage an assessment or funded account with another account with the Company or any third-party company, as determined by the Company in
its sole and absolute discretion.
<br/> ● Opening a position within 3 minutes before or after a News Event is prohibited. Any traders identified as having opened a position during a News Event are
subject to having that position closed and the associated P&L removed from their account, having the leverage on their account reduced or having their account
breached altogether. The Company has sole and absolute discretion in determining what constitutes a News Event. This rule is intended to protect the integrity of
our program and is not meant to penalize traders who inadvertently trade through a news event.
<br/> ● No Gambling Permitted : When participating in trading on both Challenge and Immediate Live Trading Accounts, traders are expected to adhere to responsible risk
management practices. This includes carefully considering the risks associated with position size, trade duration, and hedging strategies. Taking excessive risks,
such as utilizing maximum leverage to open large positions with the hope of reaching profit targets through a single price movement, is strictly prohibited. Please
refer to the Terms and Conditions for the full Prohibition of Gambling Practices language.
<br/> ● If the Company detects that your trading constitutes Prohibited Trading, your participation in the program will be terminated and may include forfeiture of any fees
paid to the Company. Additionally, and before any Trader shall receive a funded account, the trading activity of the Trader under these Terms and Conditions shall
be reviewed by both the Company and the Liquidity Provider to determine whether such trading activity constitutes Prohibited Trading. In the case of Prohibited
Trading, the Trader shall not receive a funded account.
<br/> ● Additionally, the Company reserves the right to disallow or block any Trader from participating in the program for any reason, in the Company’s sole and absolute
discretion.
To view all Prohibited Uses, please review our Terms and Conditions here, https://dashboardanalytix.com/client-terms-and-policies/?v=3acf83834396
`},

{
  question: 'How do you calculate the Daily Loss Limit? ',
  answer: `
The Daily Loss Limit is the maximum your account can lose in any given day. Daily Loss Limit is calculated using the previous day balance which resets at 5 PM
EST. The Daily Loss limit compounds with the increase in your account.
<br/>
<br/>
Example: if your prior day's end of day balance (5pm EST) was $100,000, your account would violate the daily loss limit if your equity reached $95,000 during the
day. If your floating equity is +$5,000 on a $100,000 account, your new- day (5pm EST) max loss is based on your balance from the previous day ($100,000). So,
your daily loss limit would still be $95,000.
`},

{
  question: 'How do you calculate the Trailing Max Drawdown? ',
  answer: `
The Maximum Trailing Drawdown is initially set at a specific % and trails (using CLOSED BALANCE - NOT equity) your account until you have achieved a pre-
defined % return in your account. Once you have achieved the % return, the Maximum Trailing Drawdown no longer trails and is permanently locked in at your
starting balance.
<br/>
<br/>
Example: If your starting balance is $100,000, you can drawdown to $95,000 before you would violate the Maximum Trailing Drawdown rule. Then for example let's
say you take your account to $102,000 in CLOSED BALANCE. This is your new high-water mark, which would mean your new Maximum Trailing Drawdown would
be $97,000. Next, let's say you take your account to $106,000 in CLOSED BALANCE, which would be your new high-water mark. At this point your Maximum
Trailing Drawdown would be locked in at your starting balance of $100,000. So, regardless of how high your account goes, you would only breach this rule if your
account drew back down to $100,000 (note, you can still violate the daily drawdown). For example, if you take your account to $170,000, as long as you do not
drawdown more than 5% in any given day, you would only breach if your account equity reaches $100,000.
`},

{
  question: 'Is there a breach for inactivity? ',
  answer: `
Yes. We will consider you inactive and your account will be breached if you do not have any trading activity on your account for 30 consecutive days.
`},

]},

  {
    ///////////////////////////////////
    entryTitle: 'Immediate Live Trading Accounts Rules',
    ///////////////////////////////////
    items: [

      {
        question: 'Do I need to complete KYC or sign a trader contract to start trading in an Immediate Live Trading Account?',
        answer: `
  If you do not pass the KYC process when requesting a withdrawal, the withdrawal will be rejected, and your account will be closed. We encourage you to ensure
you can meet KYC requirements before opting for the Immediate Live Trading Account.
`},
      {
        question: 'What happens if I do not pass KYC? ',
        answer: `
If you do not pass the KYC process when requesting a withdrawal, the withdrawal will be rejected, and your account will be closed. We encourage you to ensure
you can meet KYC requirements before opting for the Immediate Live Trading Account.
`},

    ]
  },

// sections end
];



